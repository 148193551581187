
/**
 * @desc format de données envoyé pour modifier une image de diaporama
 */
export class SlideshowImageEditionDto {

  constructor();
  constructor(data : any);
  constructor(data ? : any) {
    this.id = data.id;

    if(data && data.title)
      this.title = data.title;
    if(data && data.newId)
      this.newId = data.newId;
    if(data && data.order)
      this.order = data.order;

    if(data && data.src)
      this.src = data.src;
    if(data && data.imgChanged)
      this.imgChanged = data.imgChanged;
  }

  order?;
  title? : string;
  id? : number;
  newId? : number;
  src? : string;
  imgChanged? : boolean;
  
}