
  import { SlideshowImage } from '@/interfaces/SlideshowImage';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import CustomImage from '../CustomImage.component.vue';

  /**
   * @desc affichage en résumé d'une image de diaporama
   */
  @Component({
    components: {
      'c-img' : CustomImage,
    },
  })
  export default class TitledImageSum extends Vue {
    /**
     * @desc article à résumer
     */
    @Prop({default: () => {return new SlideshowImage();}})
    slideshowImage? : SlideshowImage;

    /**
     * @desc demande l'édition de l'image
     */
    editImage() : void {
      this.$emit('edit', this.slideshowImage)
    }

    /**
     * @desc demande la suppression de l'image
     */
    deleteImage() : void {
      this.$emit('delete', this.slideshowImage)
    }
  }
