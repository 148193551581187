import { SlideshowImageEditionDto } from "./SlideshowImageEditionDto";

/**
 * @desc format de données envoyées / reçues pour une image de diaporama
 */
export class SlideshowImage {

  constructor();
  constructor(data : any);
  constructor(data ? : any) {

    this.order = data && data.order || 1;
    this.title = data && data.title || "";
    this.id = data && data.id || 0;

    if(data && data.src)
      this.src = data.src;
    if(data && data.imgChanged)
      this.imgChanged = data.imgChanged;
    if(data && data.previousId)
      this.previousId = data.previousId;
  }

  order = 0;
  title : string;
  id : number;
  previousId?: number;
  src? : string;
  imgChanged? : boolean;
  

  public clone(): any {
    const cloneObj = new SlideshowImage() as any;
    for (const attribut in this) {
      cloneObj[attribut] = this[attribut];
    }
    return cloneObj;
  }

  public createSlideshowImageEditionDto(baseImg : SlideshowImage) : SlideshowImageEditionDto{
    const data : any = {};
    if(baseImg.id !== this.id)  {
      console.log("error: wrong rubric id");
      return new SlideshowImageEditionDto();
    }
    data['id'] = this.id;

    if(baseImg.title !== this.title)
      data['title'] = this.title;
    if(baseImg.imgChanged !== this.imgChanged)
      data['imgChanged'] = this.imgChanged;
    if(baseImg.src !== this.src)
      data['src'] = this.src;
    if(baseImg.order !== this.order)
      data['order'] = this.order;

    return new SlideshowImageEditionDto(data);
  }
}