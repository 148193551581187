
  import { AuthenticationService } from '@/services/authentication.service';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import {Utils} from '@/scripts/Utils';
  import TitledImageSum from './TitledImageSum.component.vue';
  import { SlideshowImage } from '@/interfaces/SlideshowImage';
  import draggable from 'vuedraggable';
  import EditSlideshowImageDialog from './EditSlideshowImageDialog.component.vue';
  import { SlideshowImageEditionDto } from '@/interfaces/SlideshowImageEditionDto';
  import { ImageTransferService } from '@/services/image-transfer.service';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import axios from 'axios';
  import DialogBoxValidation from '../DialogBoxValidation.vue';

  const editImageHeader = 'Éditer l\'image d\'accueil';
  const editImageType = 'edit';
  const createImageHeader = 'Créer une image d\'accueil';
  const createImageType = 'create';

  /**
   * @desc liste d'images avec un titre et édition possible
   */
  @Component({
    components: {
      'draggable' : draggable,
      'titled-image-sum': TitledImageSum,
      'edit-slideshow-image-dialog' : EditSlideshowImageDialog,
      'deletion-dialog-box' : DialogBoxValidation
    },
  })
  export default class TitledImageList extends Vue {
    /**
     * @desc article à résumer
     */
    @Prop({default: []})
    slideshowImages? : SlideshowImage[];

    editionImages : SlideshowImage[] = [];

    editImageHeader = editImageHeader;
    editImageType = editImageType;

    imageTransferService : ImageTransferService = new ImageTransferService();
    deletionEventName = "deleted";

    /**
     * @desc mise à jour des images à afficher
     */
    @Watch('slideshowImages')
    private imagesChanged() {
      if(this.slideshowImages)
        this.editionImages = this.slideshowImages.slice();
      else 
        this.editionImages = [];
    }

    mounted() {
      this.imagesChanged();
    }

    /**
     * @desc ouvre la boite de dialogue d'ajout d'une image de diaporama
     */
    private addImage() : void {
      this.editImageType = createImageType;
      this.editImageHeader = createImageHeader;
      (this.$refs.slideshowImageEditor as EditSlideshowImageDialog).show(new SlideshowImage(), createImageType);
    }

    /**
     * @desc ouvre la boite de dialogue de modification d'une image de diaporama
     */
    private editSlideshowImage(image: SlideshowImage) : void {
      this.editImageType = editImageType;
      this.editImageHeader = editImageHeader;
      if(this.$refs.slideshowImageEditor)
        (this.$refs.slideshowImageEditor as EditSlideshowImageDialog).show(image, editImageType);
    }

    /**
     * @desc ouvre la boite de dialogue de suppression d'une image de diaporama
     */
    private deleteSlideshowImage(image: SlideshowImage) : void {
      const dialogBox = this.$refs.deleteImageDialog as DialogBoxValidation;
      if(!dialogBox) return;
      dialogBox.show(image);
    }



    //////////////   SERVER REQUESTS

    serverDialog = false;
    /**
     * @desc demande au serveur la création d'une image de diaporama
     */
    private async serverCreateSlideshowImage(imgData: SlideshowImage) : Promise<void> {
      if(this.serverDialog) return;
      
      if(!imgData.src) return;

      this.serverDialog = true;
      imgData = await this.saveImg(imgData) as SlideshowImage;

      const headers = AuthenticationService.getRequestHeader();
       axios
        .post(UrlConsts.createSlideshowImg, imgData, headers)
        .then((response) => {
          (this.$refs.slideshowImageEditor as EditSlideshowImageDialog).hide();
          this.serverDialog = false;
          const createdImage = new SlideshowImage(response.data.slideshowImage);
          this.editionImages.splice(0, 0, createdImage);
          this.serverSetOrders();
        })
        .catch(error => this.serverError(error))
    }

    /**
     * @desc demande au serveur la modification d'une image de diaporama
     */
    private async serverEditSlideshowImage(imgData: SlideshowImageEditionDto) : Promise<void> {
      if(this.serverDialog) return;
      
      this.serverDialog = true;
      imgData = await this.saveImg(imgData) as SlideshowImage;

      const headers = AuthenticationService.getRequestHeader();
       axios
        .post(UrlConsts.editSlideshowImg, imgData, headers)
        .then((response) => {
          this.serverDialog = false;
          (this.$refs.slideshowImageEditor as EditSlideshowImageDialog).hide();
          const editedImage = new SlideshowImage(response.data.slideshowImage);
          let currentImgIndex = this.editionImages.findIndex(x => x.id === editedImage.previousId);
          if(currentImgIndex > -1) this.editionImages.splice(currentImgIndex, 1, editedImage);
        })
        .catch(error => this.serverError(error))
    }


    /**
     * @desc demande au serveur la modification de l'ordre des images de diaporama
     */
    private serverSetOrders() : void{
      if(this.serverDialog) return;
      if(!this.editionImages.length) return;

      this.serverDialog = true;

      const headers = AuthenticationService.getRequestHeader();
       axios
        .post(UrlConsts.orderSlideshowImg, 
          {slideshowImages: 
          this.editionImages.map((x: SlideshowImage, i: number) => { return {id: x.id, order: i+1}; })},
          headers)
        .then(() => {
          this.serverDialog = false;
        })
        .catch(error => this.serverError(error))
    }

    /**
     * @desc demande au serveur la suppression d'une image de diaporama
     */
    private serverDeleteSlideshowImage(image: SlideshowImage) : void {
      if(this.serverDialog) return;
      
      this.serverDialog = true;

      const headers = AuthenticationService.getRequestHeader();
       axios
        .delete(UrlConsts.deleteSlideshowImg + '/' + image.id, headers)
        .then((response) => {
          let currentImgIndex = this.editionImages.findIndex(x => x.id === image.id);
          if(currentImgIndex > -1) this.editionImages.splice(currentImgIndex, 1);
          this.serverDialog = false;
        })
        .catch(error => {
          this.serverError(error);
          this.serverDialog = false;
        })
    }

    /**
     * @desc demande la sauvegarde d'une image au serveur, et modifie l'image de diaporama donnée avec la réponse serveur
     */
    async saveImg(imgData : SlideshowImage | SlideshowImageEditionDto) : Promise<SlideshowImage | SlideshowImageEditionDto> {
      if(imgData.imgChanged) {
        if(imgData.src) {
          const imgId = await this.serverSaveImg(imgData.src);
          if(imgId && imgData.id) {
            (imgData as SlideshowImageEditionDto).newId = imgId;
          }
          else{
            (imgData as SlideshowImageEditionDto).id = imgId;
          }
        }
        else {
          imgData.id = -1;
        }
      }
      imgData.src = "";
      return imgData;
    }

    /**
     * @desc demande la sauvegarde d'une image côté serveur
     * @returns l'id de l'image sauvegardée
     */
    async serverSaveImg(imgUrl : string) : Promise<number> {
      const result = await this.imageTransferService.transferImg(imgUrl, UrlConsts.saveImg);
      if(result.createdImgId) return result.createdImgId;
      return 0;
    }

    /**
     * @desc affiche une erreur dans la communication avec le serveur
     */
    private serverError(error : string) : void{
      console.log(error);
      this.serverDialog = false;
    }
  }
