
  import { SlideshowImage } from '@/interfaces/SlideshowImage';
  import { Component, Vue } from 'vue-property-decorator';
  import SlideshowImageSelector from './SlideshowImageSelector.component.vue';

  // types utilisés pour définir le comportement du composant
  const editType = "edit";
  const createType = 'create';

  /**
   * @desc boite de dialogue permettant à l'utilisateur de créer/modifier une image de diaporama
   */
  @Component({
    components: {
      'slideshow-image-selector' : SlideshowImageSelector,
    },
  })
  export default class EditSlideshowImageDialog extends Vue {
    boxWidth = "60rem";

    visible = false;
    slideshowImg: SlideshowImage = new SlideshowImage();
    outputImg : SlideshowImage = new SlideshowImage();
    type = 'create'; // default value -> create

    /**
     * @desc message affiché lors de la validation d'un espace de texte
     */ 
    notEmptyMsg = "Ne doit pas rester vide !";


    /**
     * @desc montre la boite de dialogue de edition/creation d'une rubrique
     * @param img : object image initial
     * @param type : défini le comportement du composant, soit 'edit' soit 'create'
     */
    show(img : SlideshowImage, type : string) : void {
      this.slideshowImg = img;
      this.outputImg = img.clone();
      this.type = type;
      this.visible= true;
      this.$vuetify.theme.dark = false;

      setTimeout(() => {
        (this.$refs.imgSelector as SlideshowImageSelector).loadImg(img.id);
      }, 200);
    }

    /**
     * @desc cache la boite de dialogue
     */
    public hide() : void {
      this.visible = false;
    }

    /**
     * @desc vérifie si le paramètre input est vide ou non, règle de zone de texte
     * @param errorMsg message d'erreur à renvoyer en cas d'erreur
     */
    private notEmptyRule(input : string, errorMsg : string) : any {
      if(!input || !input.length) return errorMsg;
      return true;
    }

    private titleRules(title: string) : boolean | string {
      if(title.length < 0) return "Ne doit pas rester vide";
      return true;
    }

    /**
     * @desc fonction qui valide les données rentrées, et les renvoie en emettant un événement associé
     */
    validate() : void{
      if(!this.checkInputData()) return;

      setTimeout(() => {
        if(!this.visible) return;
        let editedImage = this.getUpdatedData();
        if(this.type === editType) {
          const slideshowEditionDto = editedImage.createSlideshowImageEditionDto(this.slideshowImg);
          if(Object.keys(slideshowEditionDto).length <= 1){
            this.cancel();
          }
          else this.$emit('validated', slideshowEditionDto)
        }
        else{
          this.$emit('created', editedImage)
        }
      }, 200);
    }

    /**
     * @desc vérifie si les données rentrées sont conformes
     * @returns false en cas de problème
     */
    checkInputData() : boolean {
      if(!this.outputImg.title) return false;
      const imgSelector = this.$refs.imgSelector as SlideshowImageSelector;
      if(imgSelector.hasChanged() && ! imgSelector.getDataUrl()){
        return false;
      }

      return true;
    }

    /**
     * @desc renvoie un object contenant toutes les données renseignées par l'utilisateur
     */
    getUpdatedData() : SlideshowImage {
      const imgSelector = this.$refs.imgSelector as SlideshowImageSelector;
      if(imgSelector.hasChanged()){
        this.outputImg.src = imgSelector.getDataUrl();
        this.outputImg.imgChanged = true;
      }

      return this.outputImg;
    }

    /**
     * @desc annule les modifications apportées. Cache simplement la boite de dialogue
     */
    cancel() : void {
      setTimeout(() => {
        this.visible = false;
      }, 200);
    }
  }

