
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '../../services/authentication.service';
  import TitledImageList from '@/components/home/TitledImageList.component.vue';
  import { SlideshowImage } from '@/interfaces/SlideshowImage';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as Consts from '@/scripts/Consts';

  /**
   * @desc page d'aministration des images du diaporama 
   */
  @Component({
    components: {
      'titled-image-list' : TitledImageList
    },
  })
  export default class HomeAdmin extends Vue {
    
    slideshowImages : Array<SlideshowImage> = []

    mounted(): void {
      if(AuthenticationService.getUserStatus() !== Consts.user_status_teacher
        && AuthenticationService.getUserStatus() !== Consts.user_status_admin){
        this.$router.push('/');
        return;
      }
      this.loadData();
    }

    /**
     * @desc charge les images du diaporama
     */
    private loadData() : void {
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(UrlConsts.getAllSlideshowImgs, headers)
        .then((response) => {
          this.slideshowImages = response.data
            .map((x: any) => new SlideshowImage(x))
            .sort((a : SlideshowImage, b : SlideshowImage) => a.order- b.order);
        })
        .catch(error => console.log(error))
    }
  }
